jconfirm.defaults = {
    draggable: true,
    escapeKey: true,
    backgroundDismiss: true,
    defaultButtons: {
        ok: {
            text: "OKAY",
            btnClass: "btn-cancel mi-write-cancel-button",
        },
        confirm: {
            text: "OKAY",
            btnClass: "btn-confirm mi-write-confirm-button",
        },
        cancel: {
            text: "CANCEL",
            btnClass: "btn-cancel mi-write-cancel-button",
        }
    },
    onOpenBefore: function () {

        if (typeof this.$$confirm != "undefined") {
            $(this.$$confirm).addClass("btn-confirm mi-write-confirm-button");
            $(this.$$confirm).removeClass("btn-default");
        }

        if (typeof this.$$confirm != "undefined") {
            $(this.$$cancel).addClass("btn-cancel mi-write-cancel-button");
            $(this.cancel).removeClass("btn-default");
        }
    }
}